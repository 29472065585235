const initialState = {
    data: ""
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SAVE_LANGUAGE':
            return {
                ...state,
            };
        case 'SAVE_LANGUAGE_SUCCESS':
            return {
                ...state,
               data: action.data
            };
        case 'SAVE_LANGUAGE_FAILURE':
            return state
        default:
            return state;
    }
}
export default reducer;