import i18next from 'i18next';
import React, { Component } from 'react';
import { hot } from 'react-hot-loader/root';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/lib/integration/react';
//language
import { config as i18nextConfig } from './translation/index.js';
// import './assets/base.scss';
import { persistor, store } from './config/configureStore';
import Routes from './Routes';
import ScrollToTop from './utils/ScrollToTop';
import ErrorBoundary from "./components/ErrorHandle/index"
i18next.init(i18nextConfig);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <BrowserRouter >
            <I18nextProvider i18n={i18next}>
              <ScrollToTop>
                <ErrorBoundary>
                <Routes />
                </ErrorBoundary>
              </ScrollToTop>
              <ToastContainer
                enableMultiContainer
                containerId={'A'}
                position={toast.POSITION.BOTTOM_LEFT}
              />
              <ToastContainer
                enableMultiContainer
                containerId={'B'}
                position={toast.POSITION.TOP_RIGHT}
              />
              <ToastContainer
                enableMultiContainer
                containerId={'C'}
                position={toast.POSITION.BOTTOM_CENTER}
              />
            </I18nextProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    );
  }
}

export default hot(App);
