import { AnimatePresence } from 'framer-motion';
import React, { lazy, Suspense } from 'react';
import { connect } from "react-redux";
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import ImageLoading from "./assets/images/loading/finalx.gif"

const MainPage = lazy(() => import('./view/index'));
const Privacy = lazy(() => import('./view/Privacy'));

const Routes = (props) => {
  const SuspenseLoading = () => {
    return (
      <>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <img src={ImageLoading} alt="loadingImage" style={{ maxHeight: "200px" }} />
          </div>
          <div className="text-muted font-size-xl text-center pt-3">
            Đang xử lý
            <span className="font-size-lg d-block text-dark">
              Vui lòng chờ trong giây lát ...
            </span>
          </div>
        </div>
      </>
    );
  };

  return (
    <AnimatePresence>
      <Suspense fallback={<SuspenseLoading />}>
        <Switch>
          <Route
            path={`/privacy`}
            component={Privacy}
          />
          <Route
            exact
            path={`/`}
            component={MainPage}
          />
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </Suspense>
    </AnimatePresence>
  );
};


const mapStateToProps = state => ({
  userInfo: state.userInfo,
})
export default connect(mapStateToProps, null)(Routes);
