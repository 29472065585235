import { combineReducers } from 'redux';
import language from "./language";
import ThemeOptions from "./ThemeOptions";
import userInfo from "./userInfo"

const appReducer = combineReducers({
  ThemeOptions,
  language,
  userInfo
});


const rootReducer = (state, action) => {
  if (action.type === 'CLEAN_STORE') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;