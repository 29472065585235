import React from "react";
import { isMobile } from 'react-device-detect';
import { withRouter } from 'react-router-dom';
import { Button } from "reactstrap";
import "./css/index.css";

const NotfoundPage = (props) => {
    const handleDirect = () => {
        props.history.push("/")
    }
    return (
        <>
            <div className="fullScreenPage d-flex flex-column px-4 text-center">
                <img src={"https://res.cloudinary.com/agridential/image/upload/v1619168523/images/404Page-removebg-preview_hu88ya.png"}
                    style={{ maxHeight: isMobile ? "250px" : "400px" }}
                    alt="notFoundImg"
                />
                <div className="textNotFound">
                    Không tìm thấy thông tin
                </div>
                <Button outline color="success" className='mt-3 btn-pill btn-gradient-info text-white' onClick={handleDirect}>Quay lại</Button>
            </div>
        </>
    )
}

export default withRouter(NotfoundPage)