export const SAVE_LANGUAGE = 'SAVE_LANGUAGE'
export const SAVE_LANGUAGE_SUCCESS = 'SAVE_LANGUAGE_SUCCESS'
export const SAVE_LANGUAGE_FAILURE = 'SAVE_LANGUAGE_FAILURE'

export const SAVE_USER_INFO = 'SAVE_USER_INFO'
export const SAVE_USER_INFO_SUCCESS = 'SAVE_USER_INFO_SUCCESS'
export const SAVE_USER_INFO_FAILURE = 'SAVE_USER_INFO_FAILURE'








