// import cookie from 'react-cookies';
import { put, takeLatest } from 'redux-saga/effects';
import { SAVE_LANGUAGE, SAVE_LANGUAGE_SUCCESS } from "../actions/types.js";

function* saveLang(lang) {
  yield put({ type: SAVE_LANGUAGE_SUCCESS, data: lang.lang })
}

export default function* rootSaga() {
  yield takeLatest(SAVE_LANGUAGE, saveLang)
  }