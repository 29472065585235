import React from "react"
import NotFoundPage from "../../view/404Page/index"
import "./index.css"
class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { error: null, errorInfo: null };
    }
    
    componentDidCatch(error, errorInfo) {
      // Catch errors in any components below and re-render with error message
      this.setState({
        error: error,
        errorInfo: errorInfo
      })
      // You can also log error messages to an error reporting service here
    }
    
    render() {
      if (this.state.errorInfo) {
        // Error path
        return (
          <div className='full-page-admin justify-content-center align-items-center d-flex flex-column'>
              <img src='https://res.cloudinary.com/agridential/image/upload/v1622044519/AGDImage/22837_mggbnr.jpg' alt='' className='maintain-img'/>
            <p className='font-weight-bold text-maintain'>HỆ THỐNG ĐANG ĐƯỢC BẢO TRÌ</p>
            <details style={{ whiteSpace: 'pre-wrap' }}>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo.componentStack}
            </details> 
          </div>
     
        );
      }
      // Normally, just render children
      return this.props.children;
    }  
  }
  
  export default ErrorBoundary;
