import { SAVE_USER_INFO, SAVE_USER_INFO_FAILURE, SAVE_USER_INFO_SUCCESS } from "../actions/types";

const initialState = {
    data: '',
  
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_USER_INFO:
            return {
                ...state,
            };
        case SAVE_USER_INFO_SUCCESS:
            return {
                ...state,
               data: action.data,
            };
        case SAVE_USER_INFO_FAILURE:
            return state
        default:
            return state;
    }
}
export default reducer;