
import reducer from '../reducers/index.js'
import { persistReducer, persistStore } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import rootSaga from '../saga/index.js'
const redux = require('redux')

const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  timeout: null
}
const pReducer = persistReducer(persistConfig, reducer)
const sagaMiddleware = createSagaMiddleware({
  onError: () => {
    console.log("error")
  }
})

const store = redux.createStore(pReducer, redux.applyMiddleware(sagaMiddleware))
const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

export { store, persistor }